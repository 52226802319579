@import "scss/material.variables";
@import "scss/material.themes";
@import "scss/mixins/bi-app/bi-app-ltr";
// Core Variables and Mixins
@import "scss/app.variables";
@import "scss/mixins";
// Core Styles
@import "scss/core";
//@import "scss/material";
@import "scss/utilities/utilities";
// Flo core
@import "scss/flo/flo.scss";

// Libs
@import "scss/components/mat-select-tz";

// custom components
@import "scss/components/spinner";

// @import "~ngx-toastr/toastr";
// data table css
@import 'node_modules/@swimlane/ngx-datatable/index.css';
@import 'node_modules/@swimlane/ngx-datatable/assets/icons.css';
@import 'node_modules/@swimlane/ngx-datatable/themes/material.scss';

:root {
  --red: red;
  --green: green;
  --yellow: #FFCC00;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Roboto", "Helvetica Neue", sans-serif !important;
  font-weight: 500 !important;
  margin-top: 0;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }
}

p {
  margin-top: 0;
  margin-bottom: 7px;

  &:last-child {
    margin-bottom: 0;
  }

  &.small {
    font-size: 14px;
    font-weight: normal;
  }
}

.material-icons {
  vertical-align: middle;
}

strong,
b {
  font-weight: 500;
}

.alert-danger {
  background-color: #f44336;
  /* Red */
}

@media (max-width: 599px) {
  .holiday-datepicker {
    // transform: translate(-42px, -204px) !important;
  }
}

/* Stop text bleed on buttons */
/*button {
  white-space: pre-wrap !important;
}*/

.underline-on-hover:hover {
  text-decoration: underline;
}

.quicklink-editor-lbl {
  color: rgba(0, 0, 0, 0.6);
}

// .mat-fab-bottom-right {
//     top: auto;
//     right: 134px !important;
//     bottom: 3.4rem !important;
//     left: auto;
//     position: fixed;
// }
html,
body {
  margin: 0;

  div {
    box-sizing: border-box;
  }
}

html body #LeadboosterContainer {
  bottom: 28px !important;
  left: 28px !important;

}

html body #LeadboosterContainer.openedChat {
  width: 415px !important;
  height: 540px !important;
  top: auto !important;
  right: auto !important;
}

.cal-week-view .cal-header.cal-today {
  color: #ffffff;
  background-color: var(--color) !important;
}

.cal-scheduler-view .cal-scheduler-headers .cal-header.cal-today {
  background-color: var(--color) !important;
}

.ngx-datatable.material {
  box-shadow: 0 3px 3px -3px rgb(0 0 0 / 20%), 0 0px 2px 0px rgb(0 0 0 / 14%),
    0 0px 3px 0px rgb(0 0 0 / 12%) !important;
}

.custom-not-availability {
  color: red;
}

.start-date-disabled {
  opacity: 0.5;
}

.mr-05,
.mr-5px {
  margin-right: 5px !important;
}

.ml-05 {
  margin-left: 5px;
}

.h-field {
  max-height: 55px;
}

.mat-text-green {
  color: #00b890 !important;
}

.mat-text-yellow {
  color: var(--yellow) !important;
}

.mat-text-blue {
  color: var(--blue) !important;
}

.mat-text-red {
  color: var(--red) !important;
}

.mat-text-theme {
  color: var(--currentStepColor) !important;
}

.mat-bg-theme {
  background-color: var(--currentStepColor) !important;
}

.mat-text-accent {
  color: var(--color) !important;
}

.mat-text-yellow {
  color: var(--yellow) !important;
}

.mat-bg-accent {
  background-color: var(--color) !important;
}

.top-0 {
  top: 0;
}

.right-0 {
  right: 0;
}

.recommended {
  padding: 5px 5px 0 5px;
  border-radius: 5px 5px 0 0;
  position: absolute;
  top: -30px;
  line-height: 15px;
  right: -1px;
  background-color: #f0f1f2;
  border: 1px solid #d9dadb;
  border-bottom: 0;
}

.recommended label {
  margin-bottom: 0;
}

.vm {
  vertical-align: middle;
}

.helpwindow .mat-mdc-dialog-container {
  padding: 0;
}

.add-edit-quote-option .mat-mdc-dialog-container {
  padding: 0px !important;
}

.timeline {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;

  li {
    flex-basis: 0;
    flex-grow: 1;
    padding: 0px;
    flex: 0 0 100%;
    max-width: 100%;
    text-align: center;
    position: relative;
    margin-bottom: 15px;

    .count {
      display: block;
      margin: 0 auto;
      height: 30px;
      width: 30px;
      border-radius: 20px;
      text-align: center;
      font-size: 12px;
      vertical-align: middle;
      line-height: 30px;
      background-color: #dddddd;
      color: #000;
      margin-bottom: 10px;
    }

    p {
      width: 100%;
      display: block;
      border-top: 2px solid #dddddd;
      padding-top: 10px;
      color: #999999;
      margin-bottom: 0;
    }

    &.done {
      .count {
        color: #ffffff;
        background-color: var(--color);
      }

      p {
        color: var(--color);
        border-top-color: var(--color);
      }
    }

    &.active {
      &:after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: -15px;
        margin: 0 auto;
        height: 10px;
        width: 10px;
        display: block;
        border-top: 10px solid var(--color);
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
      }
    }
  }
}

.app-pink .mat-mdc-raised-button:not([class*="mat-elevation-z"]) {
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 20%);
}

.mat-drawer {
  .add-chat-button {
    z-index: 999;
  }

  mat-toolbar {
    position: relative;

    mat-toolbar-row {
      z-index: 1;
    }

    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.1);
      z-index: 0;
    }
  }
}

.mt-5px {
  margin-top: 5px !important;
}

.mt-10px {
  margin-top: 10px !important;
}

.mt-15px {
  margin-top: 15px !important;
}

.md-20 {
  font-size: 20px;
}

.pb-5px {
  padding-bottom: 5px;
}

.pb-10px {
  padding-bottom: 10px;
}

.text-opac {
  opacity: 0.75;
}

.icon-24 {
  font-size: 24px;
  height: 24px;
  width: 24px;
  text-align: center;
}

.icon-16 {
  font-size: 16px;
  height: 16px !important;
  line-height: 16px;
  width: 16px !important;
  text-align: center;
}

.mat-text-secondary {
  color: #888888;
}

.padding-left-half {
  padding-left: 10px;
}

.fs-12 {
  font-size: 12px;
}

.fs-16 {
  font-size: 16px;

  &.mat-icon {
    height: 20px;
    width: 16px;
  }
}

.coverimg {
  background-size: cover;
  background-position: 50% 50%;
}

.avatar {
  color: var(--color) !important;
  display: block;
  text-align: center;
  vertical-align: middle;
  background-color: #ffffff;
  margin: 0 auto;
  box-sizing: border-box;

  svg {
    path {
      stroke: var(--color) !important;
    }
  }

  mat-icon,
  span {
    vertical-align: middle;
    margin-bottom: 0;
    opacity: 1 !important;
  }

  &.avatar-34 {
    height: 34px;
    width: 34px;
    line-height: 34px;
  }

  &.avatar-40 {
    height: 40px;
    width: 40px;
    line-height: 40px;
  }

  &.avatar-50 {
    height: 50px !important;
    width: 50px !important;
    line-height: 44px !important;
  }

  &.avatar-60 {
    height: 60px;
    width: 60px;
    line-height: 54px;
  }

  &.avatar-80 {
    height: 80px;
    width: 80px;
    line-height: 74px;
  }

  &.avatar-100 {
    height: 100px;
    width: 100px;
    line-height: 94px;
  }

  &.avatar-120 {
    height: 120px;
    width: 120px;
    line-height: 114px;
  }

  &.avatar-150 {
    height: 150px;
    width: 150px;
    line-height: 144px;
  }

  &.circle {
    border-radius: 50%;
  }
}

.mat-text-right {
  text-align: right;
}

.border-2 {
  border: 2px solid var(--currentStepColor);
}

.border-3 {
  border: 3px solid var(--currentStepColor);
}

.border {
  border: 1px solid #e2e2e2;
}

.border-bottom {
  border-bottom: 1px solid #e2e2e2 !important;
}

.border-top {
  border-top: 1px solid #e2e2e2 !important;
}

.rounded-6 {
  border-radius: 6px !important;
}

.rounded-10 {
  border-radius: 10px !important;
}

.display-block {
  display: block;
}

.font-weight-medium {
  font-weight: 500;
}

// .mat-tab-body-wrapper {
//   background-color: rgba(0, 0, 0, 0.04)
// }

body {
  .status-select {
    width: auto;
    border-radius: 22px !important;
    background-color: #d1e8ff;

    .mat-mdc-text-field-wrapper {
      padding: 0 !important;
      background-color: transparent !important;
      border-radius: 22px !important;

      .mat-mdc-form-field-infix {
        border-radius: 22px !important;
        padding: 5px 10px !important;
        border: 0 !important;
        min-height: auto;
      }

      .mdc-line-ripple {
        display: none;
      }
    }

    .mat-mdc-form-field-subscript-wrapper {
      display: none;
    }

    &.created {
      .mat-mdc-text-field-wrapper {
        .mat-mdc-form-field-infix {
          background-color: var(--blue);
        }
      }
    }

    &.completed {
      .mat-mdc-text-field-wrapper {
        .mat-mdc-form-field-infix {
          background-color: var(--green);
        }
      }
    }

    &.rejected {
      .mat-mdc-text-field-wrapper {
        .mat-mdc-form-field-infix {
          background-color: var(--red);
        }
      }
    }
  }
}

.ngx-datatable.material {
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15) !important;
}

.signature-icon {
  width: 22px;
  vertical-align: middle;
  margin-top: 4px;
}

.w-100 {
  width: 100%;
}

.w-100px {
  width: 100px;
}

.table-saperate {
  border-spacing: 0 5px;
  border-collapse: separate;
  width: 100%;

  thead {
    tr {
      th {
        font-size: 12px;
        font-weight: normal;
        z-index: 1;
        padding: 0px 10px;
      }
    }
  }

  tbody {
    position: relative !important;

    tr {
      position: relative !important;

      td {
        font-weight: normal;
        position: relative;
        z-index: 1;
        padding: 7px 10px;
        font-size: 15px;
        background-color: rgba(255, 255, 255, 0.95);

        .table-input {
          border: 1px solid #eeeeee;
          background-color: transparent;
          font-size: 15px;
          border-radius: 4px;
          padding: 5px 10px;
          box-sizing: border-box;
          width: 100%;
        }

        &:first-child {
          border-radius: 6px 0 0 6px;
        }

        &:last-child {
          border-radius: 0 6px 6px 0;
        }
      }

      // &::after {
      //   content: "";
      //   position: absolute;
      //   height: 100%;
      //   width: 100%;
      //   left: 0;
      //   top: 0;
      //   z-index: 0;
      //   background-color: rgba(255, 255, 255, 0.95);
      //   box-shadow: 0 0.05rem 0.05rem rgba(0, 0, 0, 0.02),
      //     0 0.1rem 0.4rem rgba(0, 0, 0, 0.07);
      //   border-radius: 6px;
      //   display: block;
      // }
    }
  }
}

@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) and (stroke-color: transparent) {
    .table-saperate tbody tr::after {
      display: none;
    }
  }
}

.z-index-1 {
  z-index: 1;
}

.chiptag {
  height: 26px;
  padding: 2px 8px 1px 8px;
  line-height: 22px;
  font-size: 14px;
  border-radius: 24px;
  position: relative;
  overflow: hidden;
  display: inline-block;
  margin-right: 5px;
  background-color: #ffffff;
  cursor: pointer;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;

  &.right-icon {
    padding: 2px 2px 2px 10px;
  }

  mat-icon {
    height: 24px;
    width: 24px;
    line-height: 24px;
    margin-right: 5px;
    font-size: 18px;
    border-radius: 24px;
    display: inline-block;
    position: relative;
    z-index: 1;
    vertical-align: top;
    text-align: center;

    &:first-child {
      margin-left: -6px !important;

      &:last-child {
        margin-left: 0
      }

    }
  }

  &::after {
    content: "";
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
    z-index: 0;
    opacity: 0.25;
  }

  &.yellow {
    color: var(--yellow);

    mat-icon {
      background-color: var(--yellow);
      color: #ffffff;
    }

    &:after {
      background-color: var(--yellow);
    }
  }

  &.green {
    color: var(--green);

    mat-icon {
      background-color: var(--green);
      color: #ffffff;
    }

    &:after {
      background-color: var(--green);
    }
  }

  &.red {
    color: var(--red);

    mat-icon {
      background-color: var(--red);
      color: #ffffff;
    }

    &:after {
      background-color: var(--red);
    }
  }

  &.white {
    color: #666666;

    mat-icon {
      background-color: #ffffff;
      color: #000000;
    }

    &:after {
      background-color: #ffffff;
    }
  }

  &.theme {
    color: #000000;

    mat-icon {
      background-color: var(--currentStepColor);
      color: #ffffff;
    }

    &:after {
      background-color: var(--currentStepColor);
    }
  }

  &.theme-accent {
    color: #000000;

    mat-icon {
      background-color: var(--color);
      color: #ffffff;
    }

    &:after {
      background-color: var(--color);
    }
  }

  &.small {
    height: 16px;
    padding: 2px 5px 2px 2px;
    line-height: 16px;
    font-size: 11px;
    border-radius: 24px;
  }
}

.petProfileModal {

  app-create-pet-details-v2,
  app-view-pet-details-v2,
  app-add-customer {
    &>div {
      padding: 0 !important;
      background-color: #eeeeee !important;
    }
  }

  .app-inner {
    height: auto;
  }

  mat-toolbar+div {
    padding: 15px 15px 0 15px !important;
    max-height: calc(100vh - 80px);
    overflow-y: auto;
  }
}

.view-pet-stay-modal {
  mat-toolbar+mat-dialog-content {
    padding: 15px 15px 0 15px !important;
    max-height: calc(100vh - 60px);
    overflow-y: auto;
    margin: 0;
  }
}

.invoicemodal {

  /* TODO(mdc-migration): The following rule targets internal classes of dialog that may no longer apply for the MDC version.*/
  mat-dialog-container {
    background: #f7f7f7 !important;

    // mat-dialog-content {
    //   padding: 15px 15px 0 15px !important;
    //   max-height: calc(100vh - 112px);
    //   overflow-y: auto;
    // }
  }
}

.mdc-button {
  overflow: hidden !important;
}

.mat-mdc-button {
  .mat-icon {
    font-size: 22px !important;
    height: 22px !important;
    width: 22px !important;

    &:last-child {
      margin-left: 5px;

      &:first-child {
        margin-right: 0px;
        margin-right: 0;
      }
    }

    &:first-child {
      margin-right: 5px;
    }
  }
}

body {

  .mat-mdc-fab,
  .mat-mdc-raised-button {
    // &.mat-accent {
    //   color: #ffffff;
    // }

    &.active {
      overflow: hidden;

      &:after {
        content: "";
        background: rgba(0, 0, 0, 0.15);
        position: absolute;
        height: 100%;
        width: calc(100% + 1px);
        left: -1px;
        top: 0;
        z-index: 0;
      }
    }
  }
}

.mat-accordion .mat-expansion-panel.mat-expansion-panel-spacing {
  margin: 0;
}

.mat-accordion .mat-expansion-panel {
  margin-bottom: 16px !important;
}

.scrollable-modal {
  max-width: 99vw !important;
  max-height: calc(100vh - 60px) !important;
}

.scrollable-modal-2 {
  max-height: calc(100vh - 60px) !important;
}

.mobile-view-subscription-dialog {
  max-width: 80vw !important;
  min-width: 1100px !important;
  min-height: 85vh !important;
  max-height: calc(100vh - 60px) !important;
}

.mobile-view-subscription-dialog .mat-mdc-dialog-container {
  padding: 0px !important;
}

@media only screen and (min-width: 769px) {
  .cal-week-view .cal-ends-within-day .cal-event::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 1.5px rgba(0, 0, 0, 0.3) !important;
    box-shadow: inset 0 0 1.5px rgba(0, 0, 0, 0.3) !important;
    background-color: #f5f5f5 !important;
  }

  .cal-week-view .cal-ends-within-day .cal-event::-webkit-scrollbar {
    width: 1.5px !important;
    background-color: #f5f5f5 !important;
  }

  .timeline {
    flex-direction: row;

    li {
      flex: auto;
      max-width: 100%;
    }
  }

  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
    background-color: #f5f5f5 !important;
  }

  ::-webkit-scrollbar {
    width: 6px !important;
    height: 6px;
    background-color: #f5f5f5 !important;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #000000 !important;
  }
}

@media only screen and (max-width: 769px) {
  .app-inner {
    height: calc(100vh - 56px) !important;
  }

  .mobile-view-subscription-dialog {
    min-width: 100% !important;
    min-height: 100% !important;
    overflow-y: auto;
  }

  .mobile-view-subscription-dialog .mat-mdc-dialog-container {
    padding: 0px !important;
  }
}

.deceased-pet {
  text-decoration: line-through;
}

.stats-label {
  line-height: 30px;
}

.modal-backdrop {
  z-index: 1040 !important;
}

.overflow-y {
  overflow-y: auto;
}

.quickcreate {
  width: 100% !important;
  max-width: 1100px !important;
  transition: width, height 0.2s ease;

  /* TODO(mdc-migration): The following rule targets internal classes of dialog that may no longer apply for the MDC version.*/
  mat-dialog-container {
    padding: 0;
    height: auto;
    max-height: calc(100vh - 30px);
  }
}

.availability {
  width: 400px;
  max-width: 100%;
  transition: width, height 0.2s ease;

  /* TODO(mdc-migration): The following rule targets internal classes of dialog that may no longer apply for the MDC version.*/
  mat-dialog-container {
    padding: 0;
    height: auto;
    max-height: calc(100vh - 60px);
  }
}

.quickview {
  width: 400px;
  max-width: 100% !important;
  max-height: calc(100vh - 150px);
  overflow-y: auto;
  transition: width, height 0.2s ease;

  .mat-mdc-dialog-container {

    .mat-mdc-tab-body-content {
      //max-height: calc(100vh - env(safe-area-inset-bottom));
    }

    .mdc-dialog__surface {}
  }
}

.mat-mdc-button,
.mat-mdc-raised-button,
.mat-mdc-icon-button,
.mat-mdc-outlined-button,
.mat-mdc-unelevated-button,
.mat-mdc-fab,
.mat-mdc-mini-fab {
  // font-weight: 400 !important;
}

.appointment-status {
  margin-left: 0;
  display: flex !important;
  text-align: left;

  .status {
    height: 18px;
    line-height: 18px;
    padding: 0 6px;
    font-size: 12px;
    color: #999999;
    border-radius: 15px;
    font-weight: normal;
    margin: 2px 4px 2px 0;
    white-space: nowrap;
    max-width: 60%;
    text-overflow: ellipsis;
    overflow: hidden;
    display: inlie-block;
    width: auto;

    &.created-status {
      background-color: #ffffff;
    }

    &.prepaid-status,
    &.paid-status {
      color: #ffffff;
      background-color: #0cc474;
    }

    &.pending-status {
      color: #ffffff;
      background-color: #ff8800;
    }

    &.unpaid-status {
      color: #ffffff;
      background-color: #ff3e45;
    }
  }
}

.cal-event {
  .cal-event-title {
    .mt-5px {
      p {
        font-size: 12px;
        font-weight: 500;
        margin-bottom: 5px !important;
        margin-right: 0 !important;
      }
    }

    .chiptag {
      height: 24px;
      padding: 2px 10px 2px 2px;
      line-height: 20px;
      font-size: 11px;
      border-radius: 24px;

      .mat-icon {
        height: 20px;
        width: 20px;
        line-height: 20px;
        font-size: 12px;
      }
    }

    h6 {
      font-size: 12px;
      vertical-align: middle;
      margin-right: 0 !important;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      width: 100%;
    }

    p {
      width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
      display: block;
      white-space: nowrap;
    }

    .icon-display {
      height: 20px;
      width: 20px;
      font-size: 20px;
      margin-right: 4px;
      margin-top: 0px;
    }
  }

  .example-chip {
    .example-box {
      height: 20px;
      line-height: 18px;
      padding: 1px 10px;
      font-size: 11px;
      background-color: #eeeeee;
      color: #999999;
      border-radius: 15px;
      font-weight: normal;
      margin: 2px !important;
      margin-left: 5px;
      display: inline-block;

      &.completed {
        color: #0cc474;
        background-color: #c7e7d9;
        padding: 1px 1px 1px 10px;

        span {
          color: #ffffff;
          background-color: #0cc474;
          height: 18px;
          width: 18px;
          text-align: center;
          display: inline-block;
          border-radius: 15px;
          margin-left: 3px;
        }
      }

      &.noshow {
        color: #ff8800;
        background-color: #f3e3d0;
        padding: 1px 1px 1px 10px;

        span {
          color: #ffffff;
          background-color: #ff8800;
          height: 18px;
          width: 18px;
          text-align: center;
          display: inline-block;
          border-radius: 15px;
          margin-left: 3px;
        }
      }

      &.cancelled {
        color: #ff3e45;
        background-color: #f3d3d4;
        padding: 1px 1px 1px 10px;

        span {
          color: #ffffff;
          background-color: #ff3e45;
          height: 18px;
          width: 18px;
          text-align: center;
          display: inline-block;
          border-radius: 15px;
          margin-left: 3px;
        }
      }
    }
  }
}

.mat-accordion {
  .mat-expansion-panel:not([class*="mat-elevation-z"]) {
    //  box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.15) !important;
    box-shadow: none;
    border: 1px solid rgba(0, 0, 0, 0.15);
  }
}

.mh-300 {
  max-height: 316px;
}

.overflow-y-auto {
  overflow-y: auto;
}

.cp {
  cursor: pointer !important;
}

.circle_small {
  height: 10px;
  width: 10px;
  border-radius: 10px;
  display: inline-block;
  margin-right: 5px;
  vertical-align: middle;
}

/* TODO(mdc-migration): The following rule targets internal classes of chips that may no longer apply for the MDC version.*/
/* TODO(mdc-migration): The following rule targets internal classes of chips that may no longer apply for the MDC version.*/
.mat-chip-list {
  &.small {

    /* TODO(mdc-migration): The following rule targets internal classes of chips that may no longer apply for the MDC version.*/
    mat-chip {
      padding: 2px 8px;
      min-height: 20px;
    }
  }
}

.chiptag.small {
  padding: 2px 8px;
  min-height: 18px;

  button {
    line-height: 18px;
  }
}

.christmas {
  app-header {
    .mat-toolbar {
      background-image: url(/assets/images/christmas/christmas-bg.png) !important;
      background-repeat: no-repeat !important;
      background-position: left top !important;

      .branding {
        position: relative;
        margin-left: 10px;
        overflow: visible;

        &:after {
          content: "";
          height: 30px;
          width: 30px;
          position: absolute;
          z-index: 1;
          background-image: url(/assets/images/christmas/christmas-cap.png);
          background-repeat: no-repeat;
          background-position: left top;
          left: 3px;
          top: -11px;
        }
      }

      #main-menu-toggle-button,
      #global-search-bar,
      #mobile-menu-button {
        color: #ffffff;

        .mat-icon {
          color: #ffffff !important;
        }
      }

    }
  }
}

/* calendar UI */
.cal-week-view .cal-event {
  background-color: rgba(244, 67, 54, 0.08);
  border-color: transparent;
  --font-color: #000000;
  // margin: 0 !important;
  width: calc(100% - 1px) !important;
  height: calc(100% - 2px) !important;
  top: 0px !important;
  padding: 1px 4px !important;
  border-radius: 0px !important;

  mwl-calendar-event-title {
    color: var(--font-color) !important;
  }

  &.closed {
    --font-color: #ff476d;
    background-color: rgba(255, 147, 147, 0.25);
    display: flex;
    margin: 0 !important;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    justify-content: center;
    width: 100% !important;
    height: calc(100% + 1px) !important;

    mwl-calendar-event-title {
      font-weight: 500;
    }
  }
}

app-mwl-day-view-scheduler {
  .cal-day-headers {
    padding-right: 5px;
  }
}

/* mdc update icons button */
:root {

  app-root,
  app-standard-layout,
  .cdk-overlay-container mat-dialog-container,
  app-root app-standard-layout {
    --mdc-checkbox-state-layer-size: 34px;
    --mat-form-field-focus-state-layer-opacity: 0.05;
    --mat-expansion-header-text-size: 17px;
    --mat-card-title-text-size: 17px;

    /* fonts for menu, fields and buttons */
    --mdc-list-list-item-label-text-size: 16px;
    --mdc-outlined-text-field-label-text-size: 16px;
    --mdc-typography-button-font-size: 16px;
    --mdc-typography-button-font-weight: 400;
    --mdc-typography-body2-font-size: 16px;
    --mat-toolbar-title-text-size: 18px;
    --mat-expansion-container-text-size: 16px;

    /* subtitle */
    --mat-card-subtitle-text-color: #888888;
    --mat-card-subtitle-text-font: 'Roboto', "Helvetica Neue", sans-serif;
    --mat-card-subtitle-text-tracking: 0;
    --mat-card-subtitle-text-weight: normal;

    .mat-mdc-slide-toggle {
      --mat-slide-toggle-label-text-size: 16px;
    }

    .mat-mdc-tab,
    .mat-mdc-tab-header {
      /* mat tabs*/
      --mat-tab-header-inactive-label-text-color: #222222;
      --mat-tab-header-label-text-tracking: none;
      --mat-tab-header-label-text-weight: 400;
      --mat-tab-header-label-text-size: 16px;
    }

    --mdc-list-list-item-leading-icon-color: var(--color);
    --mdc-list-list-item-label-text-tracking: none;
    --mdc-list-list-item-supporting-text-color: #888888;
    --mdc-dialog-container-color: #f7f7f7;
    --bs-border-color: #9c9c9c;
    --mdc-elevated-card-container-elevation: 0px 2px 3px 0px rgba(0, 0, 0, 0.15),
    0px 3px 8px 0px rgba(0, 0, 0, 0.15);
    --mdc-outlined-text-field-outline-color: rgba(0, 0, 0, .175);

  }
}

/* fonts for hgroup family  */
h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.75rem;
}

h4 {
  font-size: 1.5rem;
}

h5 {
  font-size: 1.25rem;
}

h6 {
  font-size: 1rem;
}

/* main container */
.container {
  max-width: 1320px;
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  margin: 0 auto;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: #fff;
}

.mdc-button .mdc-button__label {
  letter-spacing: normal;
}

.mat-mdc-fab.mat-accent {
  font-weight: 500 !important;
  color: #FFF !important;
}

.mat-mdc-raised-button.mat-accent {
  font-weight: 500 !important;
  color: #FFF !important;
}

.mat-mdc-raised-button.mat-primary {
  font-weight: 500 !important;
  color: #FFF !important;
}

mat-card {
  mat-card-header {
    padding-top: 10px !important;
    align-items: center;

    &:first-child {
      margin-bottom: 10px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 40px !important;
  min-width: 40px !important;
  padding: 8px !important;
  vertical-align: middle;

  mat-icon {
    vertical-align: top;
  }
}

.mat-mdc-raised-button>.mat-icon {
  &:first-child {
    &:last-child {
      margin: 0 !important;
    }
  }
}

.mat-mdc-unelevated-button>.mat-icon,
.mat-mdc-raised-button>.mat-icon,
.mat-mdc-outlined-button>.mat-icon {
  vertical-align: middle !important;
  font-weight: 500 !important;
  color: #FFF !important;
}

.weekskipper {
  flex-direction: row;
  box-sizing: border-box;
  display: flex;
  align-items: center;

  .mat-mdc-form-field {
    .mdc-text-field--filled:not(.mdc-text-field--disabled) {
      background-color: transparent;
      padding: 0;

      .mat-mdc-form-field-infix {
        padding-top: 4px;
        padding-bottom: 6px;
        font-size: 18px;
        font-weight: 500;
        min-height: 40px;

        .mdc-text-field__input {
          color: var(--mat-toolbar-container-text-color);
        }
      }
    }

    .mat-mdc-form-field-subscript-wrapper {
      display: none;
    }

    .mdc-line-ripple {
      display: none;
    }
  }

  .fieldduration {
    position: absolute;
    bottom: 0px;
    font-size: 10px;
    left: 0;
    width: 100%;
    text-align: center;
    margin-bottom: 0 !important;
    line-height: 10px;
  }

}

.mdc-list-item--with-leading-icon .mdc-list-item__start {
  margin-left: 10px !important;
  margin-right: 16px !important;
}

mat-nav-list .mdc-list-item__content .mat-mdc-list-item-unscoped-content .mat-icon {
  &:first-child {
    margin-right: 15px;
  }
}

.mat-mdc-form-field {
  &.mb-0 {
    .mat-mdc-form-field-subscript-wrapper {
      display: none;
    }
  }
}

.border-left {
  border-left: solid 1px var(--mat-standard-button-toggle-divider-color);
}

.position-relative {
  position: relative;
}

.d-none {
  display: none;
}

.d-block {
  display: block;
}

.mdc-m-0 {
  margin: 0 !important;
}

.mdc-mb-0 {
  margin-bottom: 0px !important;
}

.mdc-m-1 {
  margin: 10px !important;
}

.mdc-mb-1 {
  margin-bottom: 10px !important;
}

.mdc-mx-1 {
  margin-left: 10px !important;
  margin-right: 10px !important;
}

.mdc-mb-2 {
  margin-bottom: 16px !important;
}

.mdc-mb-3 {
  margin-bottom: 20px !important;
}

.mdc-mr-1 {
  margin-right: 8px !important;
}

.mdc-mr-2 {
  margin-right: 16px !important;
}

.mdc-my-1 {
  margin-bottom: 5px;
  margin-top: 5px;
}

.mdc-my-2 {
  margin-bottom: 10px !important;
  margin-top: 10px !important;
}

.mdc-my-3 {
  margin-bottom: 16px;
  margin-top: 16px;
}

.mdc-p-0 {
  padding: 0px !important;
}

.mdc-pt-0 {
  padding-top: 0px !important;
}

.mdc-pb-0 {
  padding-bottom: 0px !important;
}

.mdc-p-2 {
  padding: 10px !important;
}

.mdc-p-3 {
  padding: 16px !important;
}

.mdc-px-3 {
  padding-left: 16px;
  padding-right: 16px;
}

.mdc-px-2 {
  padding-left: 10px;
  padding-right: 10px;
}

.mdc-py-1 {
  padding-top: 5px;
  padding-bottom: 5px;
}

.mdc-py-2 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.mdc-pt-2 {
  padding-top: 10px !important;
}

.mdc-pt-3 {
  padding-top: 16px !important;
}

.text-truncated {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.mat-fab-bottom-right {
  position: fixed !important;
  bottom: 0;
  right: 0;
  margin-bottom: 15px;
  margin-right: 18px;
}

.shadow {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12) !important;
}

.text-center {
  text-align: center;
}

.fw-normal {
  font-weight: normal;
}

.text-end {
  text-align: right;
}

.border-0 {
  border: none 0;
}

.rounded-circle {
  border-radius: 50%
}

.ngx-datatable.material {
  margin: 0;
}

.datatable-footer-inner {
  padding-left: 16px;
}

.mat-mdc-tab-header {
  margin-bottom: 10px;
}

.mdc-dialog__title+.mdc-dialog__content,
.mdc-dialog__header+.mdc-dialog__content {
  padding-top: 10px !important;
}

.mat-expansion-panel-body {
  padding: 0 16px 16px !important;
}

.mat-accordion .mat-expansion-panel:not(.mat-expanded),
.mat-accordion .mat-expansion-panel:not(.mat-expansion-panel-spacing) {
  border-radius: 4px !important;
}

.alert {
  position: relative;
  padding: 10px 16px;
  margin-bottom: 16px;
  color: var(--color);
  background-color: #ffffff;
  border: 1px solid var(--color);
  border-radius: 4px;
  position: relative;
  overflow: hidden;
  z-index: 1;

  &.alert-danger {
    color: rgba(255, 29, 66, 1);
    border: 1px solid rgba(255, 29, 66, 0.75);

    &::after {
      background-color: rgba(255, 29, 66, 0.5);
    }
  }

  &.alert-success {
    color: rgb(9, 185, 141);
    border: 1px solid rgba(9, 185, 141, 0.75);

    &::after {
      background-color: rgba(9, 185, 141, 0.5);
    }
  }

  &.alert-info {
    color: rgb(64, 125, 217);
    border: 1px solid rgba(64, 125, 217, 0.75);

    &::after {
      background-color: rgba(64, 125, 217, 0.5);
    }
  }

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    background-color: var(--color);
    opacity: 0.15;
    z-index: -1;
  }

  &+* {
    position: relative;
    z-index: 1;
  }
}

.table {
  border-radius: 3px;
  caption-side: bottom;
  border-collapse: separate;
  border-spacing: 0;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15) !important;

  thead {
    tr {
      th {
        padding: 0.8rem 0.5rem;
        background-color: #ffffff;
        font-size: 15px;
        font-weight: 500;
        color: #000000;
        border-bottom: 1px solid var(--mdc-outlined-text-field-outline-color);
        border-left: 1px solid transparent;

        // border-top: 1px solid var(--mdc-outlined-text-field-outline-color);
        &:hover:not(:first-child) {
          border-left: 1px solid var(--mdc-outlined-text-field-outline-color);
        }

        &:last-child {
          // border-right: 1px solid var(--mdc-outlined-text-field-outline-color);
          border-top-right-radius: 3px;
        }

        &:first-child {
          border-top-left-radius: 3px;
        }
      }
    }
  }

  tbody {
    tr {
      td {
        padding: 0.5rem 0.5rem;
        background-color: #ffffff;
        // border-bottom: 1px solid var(--mdc-outlined-text-field-outline-color);
        // border-left: 1px solid var(--mdc-outlined-text-field-outline-color);

        &:last-child {
          // border-right: 1px solid var(--mdc-outlined-text-field-outline-color);
        }

        input,
        select {
          border: 1px solid var(--mdc-outlined-text-field-outline-color);
          border-radius: 3px;
          line-height: 24px;
          padding: 5px 10px;
        }
      }

      &:last-child {
        border-bottom: 0 none;

        td {
          //border-bottom: 1px solid var(--mdc-outlined-text-field-outline-color);

          &:first-child {
            border-bottom-left-radius: 3px;
          }

          &:last-child {
            border-bottom-right-radius: 3px;
          }
        }
      }

      &:hover,
      &:focus {
        td {
          background-color: #ebebeb;
        }
      }
    }
  }
}

.ngx-datatable {
  mat-checkbox * {
    box-sizing: content-box;
  }

  .datatable-header .datatable-header-cell .datatable-header-cell-template-wrap {
    height: inherit;
    font-weight: 500;
    font-size: 15px;
  }

  datatable-footer {
    datatable-pager {
      ul {
        li {
          a {
            height: 40px;
            width: 40px;
            display: block;
            text-align: center;
            vertical-align: middle;
            line-height: 40px;
            font-size: 16px;
            border-radius: 20px;

            i {
              height: 24px;
              width: 24px;
              vertical-align: middle;
              font-size: 24px;
              line-height: 24px;
              margin: 0 auto;
              display: inline-block;
            }

            &:hover,
            &:focus {
              background-color: rgba(0, 0, 0, 0.1);
            }
          }
        }
      }
    }
  }
}

.ngx-mat-input-tel-mat-menu-panel {
  max-height: 450px;

  .mat-mdc-menu-content {
    padding: 0;

    input {
      padding: 10px 15px !important;
      height: 56px !important;
      line-height: 36px !important;
      font-size: 16px !important;

      &:focus {
        outline: 0;
      }
    }
  }
}

.normalinput {
  padding: 5px 10px;
  line-height: 24px;
  border-radius: 6px;
  border: 1px solid var(--mdc-outlined-text-field-outline-color);
  box-sizing: border-box;
}

.mat-button-toggle-group {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2) !important;
  border: 0 !important;
  font-size: var(--mdc-typography-button-font-size);
  font-weight: normal !important;

  .mat-button-toggle {
    background-color: var(--color) !important;
    border: 0 !important;
    color: #FFF;
    font-weight: 500;

    .mat-button-toggle-label-content {
      line-height: 36px;
      padding: 0 15px;
    }
  }

  .mat-button-toggle-checked {
    color: #ffffff;

    .mat-button-toggle-button {
      background-color: rgba(0, 0, 0, 0.15);
      color: #ffffff !important;
    }
  }
}

.mat-toolbar {
  &>.mdc-mb-2 {
    margin-bottom: 10px !important;
  }
}

.ng-wizard-theme-arrows {
  border: 0 !important;

  &>ul.step-anchor {
    background-color: #ffffff !important;
    display: flex;
    margin: 0 0 15px 0;
  }

  .step-content {
    background-color: transparent !important;
    border: 0;
  }
}

.mdc-evolution-chip__graphic {
  vertical-align: middle;
}

.sidebar-wrap-mobile {
  .sidebar-mobile-header {
    display: none !important;
  }
}

.table-responsive {
  overflow-x: auto;
  padding: 4px;
  box-sizing: border-box;
}

aw-wizard-step,
aw-wizard-completion-step {
  box-sizing: border-box;
}

.d-flex {
  display: flex;
}

.w-auto {
  width: auto;
}

.h-auto {
  height: auto !important;
}

.sidebar-mobile {
  height: 100%;
  max-height: 450px;
  overflow-y: auto;
  padding-right: 15px;

  .sidebarclosebtn {
    display: none;
  }
}

.sidebar-mobile-content {
  height: 100vh;
  max-height: 450px;
  overflow-y: auto;
}

.resulttext {
  display: inline-block;

  span {
    display: inline-block;
    vertical-align: middle;
  }
}

.mdc-switch:enabled .mdc-switch__track::after {
  opacity: 0.55;
}

.mat-mdc-form-field-has-icon-suffix {
  .mdc-notched-outline {
    .mdc-notched-outline__notch {
      max-width: calc(100% - 50px) !important;
    }
  }
}

.customers-messaging-dialog {
  max-width: 1024px !important;
}

.list-features {
  mat-icon {
    height: 18px;
    width: 18px;
    font-size: 16px;
  }
}

.theme-card {
  border-color: var(--currentStepColor) !important;
  text-align: center;

  mat-card-header {
    z-index: 1;
    position: relative;

    &:after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      z-index: -1;
      background-color: var(--currentStepColor);
      opacity: 0.2;
    }
  }
}


mat-icon.small {
  height: 16px;
  width: 16px;
  font-size: 16px;
}


:root {

  app-root,
  app-standard-layout,
  .cdk-overlay-container mat-dialog-container,
  app-root app-standard-layout {
    --mdc-text-button-label-text-size: 15px;
    --mdc-text-button-label-text-tracking: 0;
    --mdc-text-button-label-text-weight: 400;
    --mdc-protected-button-label-text-size: 15px;
    --mdc-protected-button-label-text-tracking: 0;
    --mdc-protected-button-label-text-weight: 400;
    --mdc-dialog-supporting-text-color: #222222;

    .mat-mdc-slide-toggle.mat-accent {
      --mdc-switch-selected-focus-state-layer-color: var(--mat-badge-background-color);
      --mdc-switch-selected-handle-color: var(--mat-badge-background-color);
      --mdc-switch-selected-hover-state-layer-color: var(--mat-badge-background-color);
      --mdc-switch-selected-pressed-state-layer-color: var(--mat-badge-background-color);
      --mdc-switch-selected-focus-handle-color: var(--mat-badge-background-color);
      --mdc-switch-selected-hover-handle-color: var(--mat-badge-background-color);
      --mdc-switch-selected-pressed-handle-color: var(--mat-badge-background-color);
      --mdc-switch-selected-focus-track-color: var(--mat-badge-background-color);
      --mdc-switch-selected-hover-track-color: var(--mat-badge-background-color);
      --mdc-switch-selected-pressed-track-color: var(--mat-badge-background-color);
      --mdc-switch-selected-track-color: var(--mat-badge-background-color);
    }
  }
}

body {
  button.btn-yellow {
    --mdc-icon-button-icon-color: #FFC107;
    --mat-icon-button-state-layer-color: #FFC107;
    --mat-icon-button-ripple-color: rgba(255, 193, 7, 0.2);
    --mat-icon-button-ripple-color1: rgba(255, 193, 7, 0.15);
    background-color: var(--mat-icon-button-ripple-color1);
  }

  button.btn-green {
    --mdc-icon-button-icon-color: #0FAE2F;
    --mat-icon-button-state-layer-color: #0FAE2F;
    --mat-icon-button-ripple-color: rgba(15, 174, 47, 0.15);
    --mat-icon-button-ripple-color1: rgba(15, 174, 47, 0.10);
    background-color: var(--mat-icon-button-ripple-color1);
  }

  button.btn-red {
    --mdc-icon-button-icon-color: #ff0730;
    --mat-icon-button-state-layer-color: #ff0730;
    --mat-icon-button-ripple-color: rgba(255, 7, 19, 0.15);
    --mat-icon-button-ripple-color1: rgba(255, 7, 19, 0.10);
    background-color: var(--mat-icon-button-ripple-color1);
  }
}

/* calendar UI end */
@media screen and (max-width: 1439px) {
  .status {
    position: relative !important;
    width: 100%;
    text-align: left;
    right: 0;
    margin: 0 0 3px 0 !important;
    max-width: 100%;
    overflow-x: hidden;

    .apptstatus {
      border-radius: 0 4px 4px 0;

      &:before {
        content: "";
        position: absolute;
        left: -13px;
        top: 0;
        border-top: 18px solid #ffffff;
        border-left: 13px solid transparent;
      }
    }

    .paymentstatus {
      padding: 0 15px 0 5px !important;
      border-radius: 4px 0 0 4px;

      &:before {
        display: none;
      }
    }
  }

  .float-end {
    display: block !important;
    text-align: left !important;
    width: 100% !important;
  }
}

@media screen and (max-width: 1023px) {
  :root {

    app-root,
    app-standard-layout,
    .cdk-overlay-container mat-dialog-container,
    app-root app-standard-layout {
      font-size: 15px;
      --mdc-checkbox-state-layer-size: 34px;
      --mat-expansion-header-text-size: 16px;
      --mat-card-title-text-size: 16px;

      /* fonts for menu, fields and buttons */
      --mdc-list-list-item-label-text-size: 15px;
      --mdc-outlined-text-field-label-text-size: 15px;
      --mat-select-trigger-text-size: 15px;
      --mdc-typography-button-font-size: 15px;
      --mdc-typography-body2-font-size: 15px;
      --mat-toolbar-title-text-size: 16px;
      --mat-expansion-container-text-size: 15px;
      --mat-form-field-container-text-size: 15px;
      --mat-option-label-text-size: 15px;
      --mat-menu-item-label-text-size: 15px;
      --mdc-text-button-label-text-size: 14px;
      --mdc-protected-button-label-text-size: 14px;


      .mat-mdc-slide-toggle {
        --mat-slide-toggle-label-text-size: 15px;
      }

      .mat-mdc-tab,
      .mat-mdc-tab-header {
        /* mat tabs*/
        --mat-tab-header-label-text-size: 15px;
      }
    }
  }

  /* fonts for hgroup family  */
  h1 {
    font-size: 2.25rem;
  }

  h2 {
    font-size: 1.85rem;
  }

  h3 {
    font-size: 1.55rem;
  }

  h4 {
    font-size: 1.35rem;
  }

  h5 {
    font-size: 1.15rem;
  }

  h6 {
    font-size: 0.95rem;
  }

  .container {
    padding-left: 10px;
    padding-right: 10px;
  }

  mat-dialog-container {
    .mat-toolbar {
      height: auto;
      padding-top: 5px;
      padding-bottom: 5px;
      justify-content: center;

      .mat-toolbar-row,
      .mat-toolbar-single-row {
        height: auto;
        align-items: center;
      }
    }
  }

  .mat-mdc-standard-chip.mdc-evolution-chip--with-trailing-action .mdc-evolution-chip__action--primary {
    padding-left: 12px;
    padding-right: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
    display: block;
  }

  .cdk-overlay-pane {
    .mat-mdc-dialog-container {
      max-width: calc(100% - 20px) !important;
      margin: 0 auto;

      .mdc-dialog__container {
        width: 100%;
        max-width: 100% !important;

        .mdc-dialog__surface {
          max-width: 100% !important;
        }
      }
    }
  }

  .sidebar-wrap-mobile {
    overflow: hidden;
    height: 100%;
    min-height: 350px;
    position: relative;
    display: block !important;
    margin-top: 15px;
    padding-top: 0 !important;
    margin-bottom: 0px;



    .sidebar-mobile-header {
      display: flex !important;
      width: 100%;
    }

    .sidebar-mobile {
      position: absolute;
      z-index: 3;
      left: 0;
      top: 0px;
      margin-top: 5px;
      margin-bottom: 5px;
      height: calc(100% - 5px);
      max-height: none;
      display: block;
      width: 260px !important;
      max-width: 260px !important;
      background: #fff;
      padding: 15px 15px 10px 15px;
      box-shadow: 0 2px 5px rgba(0, 0, 0, .15);
      margin-left: -265px;
      transition: ease all 0.5s;
      border: 1px solid var(--mdc-outlined-card-outline-color);
      border-radius: 4px;

      .mdc-list {
        padding-left: 4px;
        padding-right: 4px;
      }

      .sidebarclosebtn {
        display: flex;
      }
    }

    .sidebar-mobile-content {
      position: relative !important;
      width: 100% !important;
      height: 100%;
      max-width: 100% !important;
      overflow-y: auto;
      padding-top: 5px;
    }

    &.active {
      .sidebar-mobile {
        margin-left: 5px;
      }
    }
  }

  .ml-2 {
    margin-left: 0 !important;
  }

  .mt-3 {
    margin-top: 10px !important;
  }

  .customers-messaging-dialog {
    max-width: 100% !important;
    width: 100% !important;
  }
}

mat-sidenav-container {
  &.mat-dark #sidebar-panel {
    --mat-sidenav-container-background-color: #222222;
    --mdc-list-list-item-label-text-color: #ffffff;
    --mdc-list-list-item-hover-label-text-color: rgba(255, 255, 255, 0.87);
    --mdc-list-list-item-hover-leading-icon-color: rgba(255, 255, 255, 0.38);
    --mdc-list-list-item-hover-state-layer-color: #ffffff;

    svg {
      fill: #ffffff;
    }

    mat-list-item {

      &:hover {
        svg {
          fill: rgba(255, 255, 255, 0.87);
        }
      }

      &.open {
        &:before {
          background-color: var(--currentStepColor) !important;
          opacity: 0.15;
        }

        svg {
          fill: rgba(255, 255, 255, 1);
        }
      }
    }
  }
}


mat-sidenav.sidebar-panel,
mat-toolbar.main-header {
  box-shadow: none !important;
}

mat-toolbar.main-header {
  border-bottom: 1px solid rgba(0, 0, 0, .1);
}

@media screen and (max-width: 767px) {
  .buttongroupmobile {
    flex-direction: column;
  }

  .ng-wizard-theme-arrows>ul.step-anchor {
    display: flex;

    &>li {
      width: 100%;

      &>a {
        padding: 10px 8px !important;
      }
    }
  }
}

// for Date pickers in landscape
@media only screen and (max-height: 575.98px) and (orientation: landscape) {

  //enter code here
  .owl-dt-container-inner {
    height: 90vh !important;
    overflow-y: auto !important;
  }

  .holiday-datepicker {
    transform: translateY(0px) !important;
    top: 5px !important;
    height: 90% !important;
  }
}


.customers-messaging-dialog {
  @media (max-width: 599px) {
    width: 98vw;
  }

  // width: 65vw;

  .mat-mdc-dialog-container {
    padding: 0px;

    .customers-email-modal {
      // max-height: 90vh;
      // height: 90vh;
      // display: flex;
      // flex-direction: column;

      .mat-mdc-form-field {
        width: 100%;
      }

      .text-bold {
        font-weight: bold;
      }

      textarea {
        resize: none;
        height: 150px;
        max-height: 150px;
      }

      .mat-mdc-dialog-content {
        // height: 80vh;
        // max-height: 80vh;

        .configuration-toggle-section {
          display: flex;
          align-content: center;
          align-items: center;
          margin: 10px !important;
        }

      }
    }
  }
}