@use "sass:map";
@use "@angular/material" as mat;
@import "core/error-styles-theme";
@import "components/table-theme";
@import "components/mat-owl-date-time";
@import "functions";

// when you need to dynamically apply theme to custom components/custom styles,
// move your styles to separate scss file and create a mixin, import it on the top
// then call your mixin bellow (inside custom-components-theme mixin).
@mixin custom-components-theme($theme) {
  @include error-styles-theme($theme);
  @include table-theme($theme);
  @include mat-owl-date-time($theme);
}

// Default Theme
@include mat.all-component-themes($theme);
@include custom-components-theme($theme);

.app-dark {
  @include mat.all-component-themes($dark-theme);
  @include custom-components-theme($dark-theme);
  @include generate-theme-vars($dark-theme);
}

.app-deep-red {
  @include mat.all-component-themes($deep-red-theme);
  @include custom-components-theme($deep-red-theme);
  @include generate-theme-vars($deep-red-theme);
}

.app-teal {
  @include mat.all-component-themes($teal-theme);
  @include custom-components-theme($teal-theme);
  @include generate-theme-vars($teal-theme);
}

.app-light-green {
  @include mat.all-component-themes($light-green-theme);
  @include custom-components-theme($light-green-theme);
  @include generate-theme-vars($light-green-theme);
}

.app-lavender {
  @include mat.all-component-themes($lavender-theme);
  @include custom-components-theme($lavender-theme);
  @include generate-theme-vars($lavender-theme);
}

.app-deep-purple {
  @include mat.all-component-themes($deep-purple-theme);
  @include custom-components-theme($deep-purple-theme);
  @include generate-theme-vars($deep-purple-theme);
}

.app-purple {
  @include mat.all-component-themes($purple-theme);
  @include custom-components-theme($purple-theme);
  @include generate-theme-vars($purple-theme);
}

.app-orange {
  @include mat.all-component-themes($orange-theme);
  @include custom-components-theme($orange-theme);
  @include generate-theme-vars($orange-theme);
}

.app-pet-trainers {
  @include mat.all-component-themes($pet-trainers-theme);
  @include custom-components-theme($pet-trainers-theme);
  @include generate-theme-vars($pet-trainers-theme);
}

.app-pet-walkers {
  @include mat.all-component-themes($pet-walkers-theme);
  @include custom-components-theme($pet-walkers-theme);
  @include generate-theme-vars($pet-walkers-theme);
}

.app-vets {
  @include mat.all-component-themes($vets-theme);
  @include custom-components-theme($vets-theme);
  @include generate-theme-vars($vets-theme);
}

.app-studio {
  @include mat.all-component-themes($studio-theme);
  @include custom-components-theme($studio-theme);
  @include generate-theme-vars($studio-theme);
}

.app-studio2 {
  @include mat.all-component-themes($studio2-theme);
  @include custom-components-theme($studio2-theme);
  @include generate-theme-vars($studio2-theme);
}

.app-studio3 {
  @include mat.all-component-themes($studio3-theme);
  @include custom-components-theme($studio3-theme);
  @include generate-theme-vars($studio3-theme);
}

.app-studio4 {
  @include mat.all-component-themes($studio4-theme);
  @include custom-components-theme($studio4-theme);
  @include generate-theme-vars($studio4-theme);
}

.app-brown {
  @include mat.all-component-themes($brown-theme);
  @include custom-components-theme($brown-theme);
  @include generate-theme-vars($brown-theme);
}

.app-green {
  @include mat.all-component-themes($green-theme);
  @include custom-components-theme($green-theme);
  @include generate-theme-vars($green-theme);
}

.app-pink {
  @include mat.all-component-themes($pink-theme);
  @include custom-components-theme($pink-theme);
  @include generate-theme-vars($pink-theme);
}

.app-mustard {
  @include mat.all-component-themes($mustard-theme);
  @include custom-components-theme($mustard-theme);
  @include generate-theme-vars($mustard-theme);
}

.app-hair-salon {
  @include mat.all-component-themes($hair-salon-theme);
  @include custom-components-theme($hair-salon-theme);
  @include generate-theme-vars($hair-salon-theme);
}

.app-nail-salon {
  @include mat.all-component-themes($nail-salon-theme);
  @include custom-components-theme($nail-salon-theme);
  @include generate-theme-vars($nail-salon-theme);
}

.app-barbers {
  @include mat.all-component-themes($barbers-theme);
  @include custom-components-theme($barbers-theme);
  @include generate-theme-vars($barbers-theme);
}

.app-tattoos {
  @include mat.all-component-themes($tattoos-theme);
  @include custom-components-theme($tattoos-theme);
  @include generate-theme-vars($tattoos-theme);
}

.app-beauty-salon {
  @include mat.all-component-themes($beauty-salon-theme);
  @include custom-components-theme($beauty-salon-theme);
  @include generate-theme-vars($beauty-salon-theme);
}
