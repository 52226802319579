.bg-spinner-wrapper {
  position: absolute;
  width: 100%;
  box-sizing: border-box;
  height: 100%;
  background: #00000070;
  z-index: 100;
  text-align: right;
  padding-top: 40vh;
  left: 0;
  top: 0;
}
