@use '@angular/material' as mat;


@mixin table-theme($theme) {
  $primary: map-get($theme, primary);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  .ngx-datatable.material {
    background: mat.get-color-from-palette($background, card);
    color: mat.get-color-from-palette($foreground, base);
    &.striped {
      .datatable-row-odd {
        background: mat.get-color-from-palette($background, 'hover');
      }
    }

    &.single-selection,
    &.multi-selection {
      .datatable-body-row {
        &.active,
        &.active .datatable-row-group {
          background-color: darken(mat.get-color-from-palette($primary), 4%);
          color: rgba(0,0,0,.87);
          // @include _mat-toolbar-color($primary);
        }

        &.active:hover,
        &.active:hover .datatable-row-group {
          background-color: darken(mat.get-color-from-palette($primary), 2%);
        }

        &.active:focus,
        &.active:focus .datatable-row-group {
          background-color: darken(mat.get-color-from-palette($primary), 4%);
        }
      }
    }

    &:not(.cell-selection) {
      .datatable-body-row {
        &:hover,
        &:hover .datatable-row-group {
          background: mat.get-color-from-palette($background, 'hover');
        }

        &:focus,
        &:focus .datatable-row-group {
          background-color: darken(mat.get-color-from-palette($background, 'hover'), 2%);;
        }
      }
    }

    &.cell-selection {
      .datatable-body-cell {
        &:hover,
        &:hover .datatable-row-group {
          background: mat.get-color-from-palette($background, 'hover');
        }

        &:focus,
        &:focus .datatable-row-group {
          background-color: darken(mat.get-color-from-palette($background, 'hover'), 2%);;
        }

        &.active,
        &.active .datatable-row-group {
          background-color: darken(mat.get-color-from-palette($primary), 4%);
          color: rgba(0,0,0,.87);
          // @include _mat-toolbar-color($primary);
        }

        &.active:hover,
        &.active:hover .datatable-row-group {
          background-color: darken(mat.get-color-from-palette($primary), 2%);
        }

        &.active:focus,
        &.active:focus .datatable-row-group {
          background-color: darken(mat.get-color-from-palette($primary), 4%);
        }
      }
    }
    @if $theme == $dark-theme {
      .datatable-header .datatable-row-left, .datatable-body .datatable-row-left,
      .datatable-header .datatable-row-right, .datatable-body .datatable-row-right {
        background-color: rgba(73, 73, 73, 1);
      }
    }


    /**
     * Header Styles
     */
    .datatable-header {
      border-bottom: 1px solid mat.get-color-from-palette($foreground, divider);

      .datatable-header-cell {
        color: mat.get-color-from-palette($foreground, base);
      }

      .resize-handle {
        border-right:solid 1px mat.get-color-from-palette($foreground, divider);
      }
    }

    /**
     * Footer Styles
     */
    .datatable-footer {
      border-top: 1px solid mat.get-color-from-palette($foreground, divider);
      .datatable-pager {
        li {
          &.disabled a{
            @if $theme == $dark-theme {
              color: rgba(255, 255, 255, 0.26) !important;
            }
          }
          a {
            color: mat.get-color-from-palette($foreground, base);
          }
          &.active a{
            background: mat.get-color-from-palette($primary);
          }
        }
      }
    }
  }
  .ngx-datatable.flo-material {
    background: mat.get-color-from-palette($background, card);
    color: mat.get-color-from-palette($foreground, base);
    &.striped {
      .datatable-row-odd {
        background: mat.get-color-from-palette($background, 'hover');
      }
    }

    &.single-selection,
    &.multi-selection {
      .datatable-body-row {
        &.active,
        &.active .datatable-row-group {
          background-color: darken(mat.get-color-from-palette($primary), 4%);
          color: rgba(0,0,0,.87);
          // @include _mat-toolbar-color($primary);
        }

        &.active:hover,
        &.active:hover .datatable-row-group {
          background-color: darken(mat.get-color-from-palette($primary), 2%);
        }

        &.active:focus,
        &.active:focus .datatable-row-group {
          background-color: darken(mat.get-color-from-palette($primary), 4%);
        }
      }
    }

    &:not(.cell-selection) {
      .datatable-body-row {
        &:hover,
        &:hover .datatable-row-group {
          background: mat.get-color-from-palette($background, 'hover');
        }

        &:focus,
        &:focus .datatable-row-group {
          background-color: darken(mat.get-color-from-palette($background, 'hover'), 2%);;
        }
      }
    }

    &.cell-selection {
      .datatable-body-cell {
        &:hover,
        &:hover .datatable-row-group {
          background: mat.get-color-from-palette($background, 'hover');
        }

        &:focus,
        &:focus .datatable-row-group {
          background-color: darken(mat.get-color-from-palette($background, 'hover'), 2%);;
        }

        &.active,
        &.active .datatable-row-group {
          background-color: darken(mat.get-color-from-palette($primary), 4%);
          color: rgba(0,0,0,.87);
          // @include _mat-toolbar-color($primary);
        }

        &.active:hover,
        &.active:hover .datatable-row-group {
          background-color: darken(mat.get-color-from-palette($primary), 2%);
        }

        &.active:focus,
        &.active:focus .datatable-row-group {
          background-color: darken(mat.get-color-from-palette($primary), 4%);
        }
      }
    }
    @if $theme == $dark-theme {
      .datatable-header .datatable-row-left, .datatable-body .datatable-row-left,
      .datatable-header .datatable-row-right, .datatable-body .datatable-row-right {
        background-color: rgba(73, 73, 73, 1);
      }
    }


    /**
     * Header Styles
     */
    .datatable-header {
      border-bottom: 1px solid mat.get-color-from-palette($foreground, divider);

      .datatable-header-cell {
        color: mat.get-color-from-palette($foreground, base);
      }

      .resize-handle {
        border-right:solid 1px mat.get-color-from-palette($foreground, divider);
      }
    }

    /**
     * Footer Styles
     */
    .datatable-footer {
      border-top: 1px solid mat.get-color-from-palette($foreground, divider);
      .datatable-pager {
        li {
          &.disabled a{
            @if $theme == $dark-theme {
              color: rgba(255, 255, 255, 0.26) !important;
            }
          }
          a {
            color: mat.get-color-from-palette($foreground, base);
          }
          &.active a{
            background: mat.get-color-from-palette($primary);
          }
        }
      }
    }
  }
}
