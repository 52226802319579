@use "@angular/material" as mat;
/* $scaffolding
 ------------------------------------------*/
html {
  width: 100%;
  height: 100%;
  position: relative;
  margin: 0;
}

body {
  width: 100%;
  height: 100%;
  position: relative;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-touch-callout: none;
  min-height: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  line-height: 1.4;
  font-family: $mat-font-family;

  &[dir="rtl"],
  &[dir="ltr"] {
    unicode-bidi: embed;
  }
  &.pace-done {
    background: #6b6b6b;
  }
}

[tabindex="-1"]:focus {
  outline: none;
}

bdo[dir="rtl"] {
  direction: rtl;
  unicode-bidi: bidi-override;
}
bdo[dir="ltr"] {
  direction: ltr;
  unicode-bidi: bidi-override;
}

.mat-mdc-card.settings-panel {
  position: fixed;
  bottom: 6px;
  right: 6px;
  width: 250px;
  z-index: 9;
}
