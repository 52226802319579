@use "@angular/material" as mat;
mat.$theme-ignore-duplication-warnings: true;

/* Default light theme */
$primary: mat.define-palette(mat.$indigo-palette);
$accent: mat.define-palette(mat.$amber-palette, A200, A100, A400);
$warn: mat.define-palette(mat.$red-palette);
$theme: mat.define-light-theme(
  (
    color: (
      primary: $primary,
      accent: $accent,
      warn: $warn,
    ),
    typography: mat.define-typography-config(),
    density: 0,
  )
);
$background: map-get($theme, background);
$foreground: map-get($theme, foreground);

/* Deep Red */
$deep-red-primary: mat.define-palette(mat.$red-palette, A700);
$deep-red-accent: mat.define-palette(
  mat.$deep-purple-palette,
  A200,
  A100,
  A400
);
$deep-red-warn: mat.define-palette(mat.$amber-palette);

$deep-red-theme: mat.define-light-theme(
  (
    color: (
      primary: $deep-red-primary,
      accent: $deep-red-accent,
      warn: $deep-red-warn,
    ),
    typography: mat.define-typography-config(),
    density: 0,
  )
);
$deep-red-background: map-get($deep-red-theme, background);
$deep-red-foreground: map-get($deep-red-theme, foreground);

/* Teal */
$teal-primary: mat.define-palette(mat.$teal-palette, A700);
$teal-accent: mat.define-palette(mat.$lime-palette, A200, A100, A400);
$teal-warn: mat.define-palette(mat.$red-palette);

$teal-theme: mat.define-light-theme(
  (
    color: (
      primary: $teal-primary,
      accent: $teal-accent,
      warn: $teal-warn,
    ),
    typography: mat.define-typography-config(),
    density: 0,
  )
);
$teal-background: map-get($teal-theme, background);
$teal-foreground: map-get($teal-theme, foreground);

/* Green */
$light-green-primary: mat.define-palette(mat.$blue-grey-palette, 700);
$light-green-accent: mat.define-palette(mat.$light-green-palette, 500);
$light-green-warn: mat.define-palette(mat.$red-palette);

$light-green-theme: mat.define-light-theme(
  (
    color: (
      primary: $light-green-primary,
      accent: $light-green-accent,
      warn: $light-green-warn,
    ),
    typography: mat.define-typography-config(),
    density: 0,
  )
);
$light-green-background: map-get($light-green-theme, background);
$light-green-foreground: map-get($light-green-theme, foreground);

/* Lavender */
$lavender-primary: mat.define-palette(mat.$indigo-palette, 100);
$lavender-accent: mat.define-palette(mat.$orange-palette, 500, 100);
$lavender-warn: mat.define-palette(mat.$deep-orange-palette, 500);

$lavender-theme: mat.define-light-theme(
  (
    color: (
      primary: $lavender-primary,
      accent: $lavender-accent,
      warn: $lavender-warn,
    ),
    typography: mat.define-typography-config(),
    density: 0,
  )
);
$lavender-background: map-get($lavender-theme, background);
$lavender-foreground: map-get($lavender-theme, foreground);

/* Deep Purple */
$deep-purple-primary: mat.define-palette(mat.$deep-purple-palette, A700);
$deep-purple-accent: mat.define-palette(mat.$amber-palette, A200, A100, A400);
$deep-purple-warn: mat.define-palette(mat.$red-palette);

$deep-purple-theme: mat.define-light-theme(
  (
    color: (
      primary: $deep-purple-primary,
      accent: $deep-purple-accent,
      warn: $deep-purple-warn,
    ),
    typography: mat.define-typography-config(),
    density: 0,
  )
);
$deep-purple-background: map-get($deep-purple-theme, background);
$deep-purple-foreground: map-get($deep-purple-theme, foreground);

/* Purple */
$purple-primary: mat.define-palette(mat.$deep-purple-palette, A100);
$purple-accent: mat.define-palette(mat.$cyan-palette, 100, A100, A400);
$purple-warn: mat.define-palette(mat.$red-palette);

$purple-theme: mat.define-light-theme(
  (
    color: (
      primary: $purple-primary,
      accent: $purple-accent,
      warn: $purple-warn,
    ),
    typography: mat.define-typography-config(),
    density: 0,
  )
);
$purple-background: map-get($purple-theme, background);
$purple-foreground: map-get($purple-theme, foreground);

/* Orange */
$orange-primary: mat.define-palette(mat.$blue-grey-palette);
$orange-accent: mat.define-palette(mat.$amber-palette);
$orange-warn: mat.define-palette(mat.$red-palette);

$orange-theme: mat.define-light-theme(
  (
    color: (
      primary: $orange-primary,
      accent: $orange-accent,
      warn: $orange-warn,
    ),
    typography: mat.define-typography-config(),
    density: 0,
  )
);
$orange-background: map-get($orange-theme, background);
$orange-foreground: map-get($orange-theme, foreground);

/* Pet Trainers */
$pet-trainers-primary: mat.define-palette(mat.$blue-grey-palette, 100);
$pet-trainers-accent: mat.define-palette(mat.$orange-palette, 700);
$pet-trainers-warn: mat.define-palette(mat.$red-palette, 700);

$pet-trainers-theme: mat.define-light-theme(
  (
    color: (
      primary: $pet-trainers-primary,
      accent: $pet-trainers-accent,
      warn: $pet-trainers-warn,
    ),
    typography: mat.define-typography-config(),
    density: 0,
  )
);
$pet-trainers-background: map-get($pet-trainers-theme, background);
$pet-trainers-foreground: map-get($pet-trainers-theme, foreground);

/* Pet Walkers */
$pet-walkers-primary: mat.define-palette(mat.$green-palette, 100);
$pet-walkers-accent: mat.define-palette(mat.$teal-palette, 700);
$pet-walkers-warn: mat.define-palette(mat.$light-green-palette, 700);

$pet-walkers-theme: mat.define-light-theme(
  (
    color: (
      primary: $pet-walkers-primary,
      accent: $pet-walkers-accent,
      warn: $pet-walkers-warn,
    ),
    typography: mat.define-typography-config(),
    density: 0,
  )
);
$pet-walkers-background: map-get($pet-walkers-theme, background);
$pet-walkers-foreground: map-get($pet-walkers-theme, foreground);

/* Vets */
$vets-primary: mat.define-palette(mat.$blue-palette, 500);
$vets-accent: mat.define-palette(mat.$blue-palette, 100);
$vets-warn: mat.define-palette(mat.$indigo-palette, 100);

$vets-theme: mat.define-light-theme(
  (
    color: (
      primary: $vets-primary,
      accent: $vets-accent,
      warn: $vets-warn,
    ),
    typography: mat.define-typography-config(),
    density: 0,
  )
);
$vets-background: map-get($vets-theme, background);
$vets-foreground: map-get($vets-theme, foreground);

/* Studio - Theme 1 */
$studio-primary: mat.define-palette(mat.$deep-purple-palette, A100);
$studio-accent: mat.define-palette(mat.$deep-purple-palette, 400);
$studio-warn: mat.define-palette(mat.$purple-palette, 700);

$studio-theme: mat.define-light-theme(
  (
    color: (
      primary: $studio-primary,
      accent: $studio-accent,
      warn: $studio-warn,
    ),
    typography: mat.define-typography-config(),
    density: 0,
  )
);
$studio-background: map-get($studio-theme, background);
$studio-foreground: map-get($studio-theme, foreground);

/* Studio - Theme 2 */
$studio2-primary: mat.define-palette(mat.$deep-purple-palette, 500);
$studio2-accent: mat.define-palette(mat.$red-palette, 700);
$studio2-warn: mat.define-palette(mat.$red-palette, A200);

$studio2-theme: mat.define-light-theme(
  (
    color: (
      primary: $studio2-primary,
      accent: $studio2-accent,
      warn: $studio2-warn,
    ),
    typography: mat.define-typography-config(),
    density: 0,
  )
);
$studio2-background: map-get($studio2-theme, background);
$studio2-foreground: map-get($studio2-theme, foreground);

/* Studio - Theme 3 */
$studio3-primary: mat.define-palette(mat.$purple-palette, 700);
$studio3-accent: mat.define-palette(mat.$purple-palette, 100);
$studio3-warn: mat.define-palette(mat.$purple-palette, 500);

$studio3-theme: mat.define-light-theme(
  (
    color: (
      primary: $studio3-primary,
      accent: $studio3-accent,
      warn: $studio3-warn,
    ),
    typography: mat.define-typography-config(),
    density: 0,
  )
);
$studio3-background: map-get($studio3-theme, background);
$studio3-foreground: map-get($studio3-theme, foreground);

/* Studio - Theme 4 */
$studio4-primary: mat.define-palette(mat.$indigo-palette, 700);
$studio4-accent: mat.define-palette(mat.$blue-grey-palette, 100);
$studio4-warn: mat.define-palette(mat.$purple-palette, 700);

$studio4-theme: mat.define-light-theme(
  (
    color: (
      primary: $studio4-primary,
      accent: $studio4-accent,
      warn: $studio4-warn,
    ),
    typography: mat.define-typography-config(),
    density: 0,
  )
);
$studio4-background: map-get($studio4-theme, background);
$studio4-foreground: map-get($studio4-theme, foreground);

/* Brown */

$brown-primary: mat.define-palette(mat.$brown-palette, 200, 300, 900);
$brown-accent: mat.define-palette(mat.$cyan-palette, A100);
$brown-warn: mat.define-palette(mat.$deep-orange-palette, A200);

$brown-theme: mat.define-light-theme(
  (
    color: (
      primary: $brown-primary,
      accent: $brown-accent,
      warn: $brown-warn,
    ),
    typography: mat.define-typography-config(),
    density: 0,
  )
);
$brown-background: map-get($brown-theme, background);
$brown-foreground: map-get($brown-theme, foreground);

/* Green */

$green-primary: mat.define-palette(mat.$green-palette, 500);
$green-accent: mat.define-palette(mat.$green-palette, 200);
$green-warn: mat.define-palette(mat.$grey-palette, 400);

$green-theme: mat.define-light-theme(
  (
    color: (
      primary: $green-primary,
      accent: $green-accent,
      warn: $green-warn,
    ),
    typography: mat.define-typography-config(),
    density: 0,
  )
);
$green-background: map-get($green-theme, background);
$green-foreground: map-get($green-theme, foreground);

/* Pink */

$pink-primary: mat.define-palette(mat.$teal-palette, 200);
$pink-accent: mat.define-palette(mat.$red-palette, 200);
$pink-warn: mat.define-palette(mat.$red-palette, 400);

// $pink-primary: mat.define-palette(mat.$teal-palette, 200);
// $pink-accent: mat.define-palette(mat.$pink-palette, 300);
// $pink-warn: mat.define-palette(mat.$pink-palette, 300);

$pink-theme: mat.define-light-theme(
  (
    color: (
      primary: $pink-primary,
      accent: $pink-accent,
      warn: $pink-warn,
    ),
    typography: mat.define-typography-config(),
    density: 0,
  )
);
$pink-background: map-get($pink-theme, background);
$pink-foreground: map-get($pink-theme, foreground);

/* Mustard */

/*$mustard-primary: mat.define-palette(mat.$indigo-palette);
$mustard-accent:  mat.define-palette(mat.$amber-palette, A200, A100, A400);
$mustard-warn: mat.define-palette(mat.$red-palette);*/

$mustard-primary: mat.define-palette(mat.$cyan-palette, 600);
$mustard-accent: mat.define-palette(mat.$amber-palette, 700);
$mustard-warn: mat.define-palette(mat.$red-palette, 400);

$mustard-theme: mat.define-light-theme(
  (
    color: (
      primary: $mustard-primary,
      accent: $mustard-accent,
      warn: $mustard-warn,
    ),
    typography: mat.define-typography-config(),
    density: 0,
  )
);
$mustard-background: map-get($mustard-theme, background);
$mustard-foreground: map-get($mustard-theme, foreground);

// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-typography-config());`
// @include mat.all-legacy-component-typographies();
@include mat.typography-hierarchy(mat.define-typography-config());
@include mat.core();

/* Default dark theme */
$dark-primary: mat.define-palette(mat.$blue-grey-palette);
$dark-accent: mat.define-palette(mat.$amber-palette, A200, A100, A400);
$dark-warn: mat.define-palette(mat.$deep-orange-palette);
$dark-theme: mat.define-dark-theme(
  (
    color: (
      primary: $dark-primary,
      accent: $dark-accent,
      warn: $dark-warn,
    ),
    typography: mat.define-typography-config(),
    density: 0,
  )
);
$dark-background: map-get($dark-theme, background);
$dark-foreground: map-get($dark-theme, foreground);

$config: mat.define-typography-config();

/* Hair Salon */
$hair-salon-primary: mat.define-palette(mat.$indigo-palette, 100);
$hair-salon-accent: mat.define-palette(mat.$deep-purple-palette, 500);
$hair-salon-warn: mat.define-palette(mat.$cyan-palette, 100);

$hair-salon-theme: mat.define-light-theme(
  (
    color: (
      primary: $hair-salon-primary,
      accent: $hair-salon-accent,
      warn: $hair-salon-warn,
    ),
    typography: mat.define-typography-config(),
    density: 0,
  )
);
$hair-salon-background: map-get($hair-salon-theme, background);
$hair-salon-foreground: map-get($hair-salon-theme, foreground);

/*Nail Salon*/
$nail-salon-primary: mat.define-palette(mat.$pink-palette, 100);
$nail-salon-accent: mat.define-palette(mat.$cyan-palette, 500);
$nail-salon-warn: mat.define-palette(mat.$yellow-palette, 800);

$nail-salon-theme: mat.define-light-theme(
  (
    color: (
      primary: $nail-salon-primary,
      accent: $nail-salon-accent,
      warn: $nail-salon-warn,
    ),
    typography: mat.define-typography-config(),
    density: 0,
  )
);
$nail-salon-background: map-get($nail-salon-theme, background);
$nail-salon-foreground: map-get($nail-salon-theme, foreground);

/* Barbers */
$barbers-primary: mat.define-palette(mat.$grey-palette, 900);
$barbers-accent: mat.define-palette(mat.$red-palette, 700);
$barbers-warn: mat.define-palette(mat.$red-palette, 700);

$barbers-theme: mat.define-light-theme(
  (
    color: (
      primary: $barbers-primary,
      accent: $barbers-accent,
      warn: $barbers-warn,
    ),
    typography: mat.define-typography-config(),
    density: 0,
  )
);
$barbers-background: map-get($barbers-theme, background);
$barbers-foreground: map-get($barbers-theme, foreground);

/* Tattoos */
$tattoos-primary: mat.define-palette(mat.$deep-purple-palette, 500);
$tattoos-accent: mat.define-palette(mat.$red-palette, 700);
$tattoos-warn: mat.define-palette(mat.$red-palette, A200);

$tattoos-theme: mat.define-light-theme(
  (
    color: (
      primary: $tattoos-primary,
      accent: $tattoos-accent,
      warn: $tattoos-warn,
    ),
    typography: mat.define-typography-config(),
    density: 0,
  )
);
$tattoos-background: map-get($tattoos-theme, background);
$tattoos-foreground: map-get($tattoos-theme, foreground);

/* Beauty Salon */
$beauty-salon-primary: mat.define-palette(mat.$pink-palette, 100);
$beauty-salon-accent: mat.define-palette(mat.$pink-palette, A200);
$beauty-salon-warn: mat.define-palette(mat.$red-palette, A200);

$beauty-salon-theme: mat.define-light-theme(
  (
    color: (
      primary: $beauty-salon-primary,
      accent: $beauty-salon-accent,
      warn: $beauty-salon-warn,
    ),
    typography: mat.define-typography-config(),
    density: 0,
  )
);
$beauty-salon-background: map-get($beauty-salon-theme, background);
$beauty-salon-foreground: map-get($beauty-salon-theme, foreground);
