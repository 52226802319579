@import "../app.variables";

.zero-image svg {
  height: 110px;
  width: 110px;
}

.zero-image {
  height: auto !important;
  width: 100% !important;
  font-size: 24px !important;
}

.full-height {
  height: 100%;
}

.no-shadow {
  box-shadow: none !important;
}

.example-spacer {
  flex: 1 1 auto;
}

.dashboard-card {
  @media (min-width: map-get($grid-breakpoints, md)) {
    height: 242px;
    box-sizing: border-box;
    overflow: hidden;
  }
}

mwl-calendar-month-view *,
mwl-calendar-month-cell,
mwl-calendar-day-view-hour-segment,
mwl-calendar-week-view-hour-segment {
  //touch-action: auto !important;
  touch-action: initial !important;
  user-select: initial !important;
  -webkit-user-drag: initial !important;
}

.cal-event {
  box-sizing: border-box !important;
  position: relative;
  z-index: 999 !important;
}

.cal-event-container {
  margin-left: 0 !important;
}

/*.hide-calendar-events {
  display: none;
}*/

.refer-panel {
  width: 55vw !important;
  @media (max-width: 599px) {
    width: 100% !important;
  }
}

.mat-drawer-content {
  background-color: #ffffff;
  overflow-x: hidden !important;
}
.drop-zone {
  border-radius: 0 !important;
  border: none !important;

  .content {
    color: grey !important;
  }
}
.entitySummaryComponent {
  .mat-mdc-dialog-container {
    padding: 0 !important;
    -webkit-overflow-scrolling: touch !important;
  }

  @media (max-width: 599px) {
    max-height: 90vh !important;
  }
}
.dropdown-menu {
  list-style: none;
  background: white;
  padding: 0;
}

.modal-100 {
  width: 100%;
}
