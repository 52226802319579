@use '@angular/material' as mat;


@mixin mat-owl-date-time($theme) {
  $primary: map-get($theme, primary);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  owl-date-time-container {
    .owl-dt-container-buttons {
      color: mat.get-color-from-palette($primary);
    }
    .owl-dt-calendar-table .owl-dt-calendar-cell-selected {
      background-color: mat.get-color-from-palette($primary);
    }
  }
}
