.radius-none {
  border-radius: 0;
}
.radius-round {
  border-radius: $border-radius-base;
}
.radius-circle {
  border-radius: 50%;
}
.overflow-visible {
  overflow: visible;
}
.overflow-hidden {
  overflow: hidden;
}
.overflow-auto {
  overflow: auto;
}
.block {
  display: block;
}
.button-block {
  display: block;
  width: 100%;
}
.inline-block {
  display: inline-block;
}
.center-block {
  display: block;
  margin: 0 auto;
}
.relative {
  position: relative;
}
.fixed {
  position: fixed;
}
.absolute {
  position: absolute;
}
.shadow-none {
  box-shadow: none!important;
}
.border-none {
  border: 0!important;
}
.background-none {
  background-color: transparent!important;
}
.bg-cover {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.w-100 {
  width: 100%;
}
.max-w-100 {
  max-width: 100% !important;
}
.h-100 {
  height: 100%;
}
.pointer {
  cursor: pointer;
}
