.mat-mdc-select ng2-timezone-picker {
  .select2-container {
    .selection {
      max-width: 0;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      .select2-selection--single {
        outline: none !important;
        border: none;
        border-radius: 0;
        .select2-selection__rendered {
          padding-left: 0;
          line-height: initial;
        }
        .select2-selection__arrow {
          height: 1.125em;
          b {
            border-width: 5px 5px 0 5px;
            margin-left: -3px;
          }
        }
      }
    }
  }
  .select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
    border-width: 0 5px 5px 5px;
  }
}

.select2-dropdown {
  border: none;
  border-radius: 0;
  box-shadow: 0 5px 5px -3px rgba(0,0,0,.2), 0 8px 10px 1px rgba(0,0,0,.14), 0 3px 14px 2px rgba(0,0,0,.12);
  .select2-results__option--highlighted[aria-selected] {
    background-color: rgba(0,0,0,.04);
    color: inherit;
  }
  .select2-search.select2-search--dropdown .select2-search__field {
    font: inherit;
    background: 0 0;
    color: currentColor;
    border: none;
    outline: 0;
    padding: 0;
    margin: 0;
    width: 100%;
    max-width: 100%;
    vertical-align: bottom;
    text-align: inherit;
  }
}
