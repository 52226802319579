@use "sass:math";

@use '@angular/material' as mat;
/* $Widget
 ------------------------------------------*/
.ngx-datatable.material {
  &.has-plus-btn {
    margin-bottom: 80px;
  }
  padding: 0;
  margin: math.div($gutter, 3);
  @include mat.elevation(2);
  @include mat.elevation-transition;
  display: block;
  position: relative;
  border-radius: $border-radius-base;
  &.fullscreen {
    position: absolute !important;
    height: auto !important;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  &.scroll-vertical {
    height: 70vh;
  }

  &.single-selection,
  &.multi-selection {
    .datatable-body-row {
      &.active:hover,
      &.active:hover .datatable-row-group {
        color: #FFF;
      }
      &.active:focus,
      &.active:focus .datatable-row-group {
        color: #FFF;
      }
    }
  }


  &.cell-selection {
    .datatable-body-cell {
      &.active:hover,
      &.active:hover .datatable-row-group {
        color: #FFF;
      }

      &.active:focus,
      &.active:focus .datatable-row-group {
        color: #FFF;
      }
    }
  }

  /**
   * Shared Styles
   */
  .empty-row{
     padding: .85rem 1.2rem;
  }

  .loading-row{
     padding: .85rem 1.2rem;
  }

  /**
   * Header Styles
   */
  .datatable-header {
    .datatable-header-cell {
      padding: .85rem 1.2rem;
      vertical-align: middle;
      &.longpress {
        color:#00E676;
      }
      &.dragging {
        .resize-handle {
          border-right: none;
        }
      }
    }
  }

  /**
   * Body Styles
   */
  .datatable-body {
    .datatable-row-detail {
      background: #f5f5f5;
      padding: 10px;
    }

    .datatable-body-row {
      .datatable-body-cell {
        padding: .85rem 1.2rem;
        box-sizing: border-box;
        max-height: 100%;
        display: flex;
        flex-direction: row;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        justify-content: flex-start;
        align-items: center;
        align-content: center;
        -webkit-box-pack: start;
        -webkit-box-align: center;
        .datatable-body-cell-label {
          width: 100%;
        }
      }
    }

    .progress-linear {
      .container {
        background-color: rgb(170,209,249);

        .bar {
          background-color: rgb(16,108,200);
        }
      }
    }
  }

  /**
   * Footer Styles
   */
  .datatable-footer {
    .datatable-pager {
      li {
        vertical-align: middle;

        &.disabled a{
          color: rgba(0, 0, 0, 0.26) !important;
          background-color: transparent !important;
        }

        &.active a{
          color: white;
          font-weight: $font-weight-base;
        }
      }
    }
  }
}

.ngx-datatable.payments-tab-view {
  &.scroll-vertical {
    height: 100%;
  }
  .datatable-body {
    height: 100% !important;
  }
}

[dir=rtl] {
  .ngx-datatable.material .datatable-header .datatable-header-cell {
    text-align: right;
  }
  .ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-cell {
    text-align: right;
  }
  .ngx-datatable.material .empty-row{
     text-align: right;
  }
  .ngx-datatable.material .loading-row{
     text-align: right;
  }
  .ngx-datatable .datatable-footer .datatable-pager {
    text-align: left;
  }
}
