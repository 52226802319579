@each $prop, $abbrev in (margin: m, padding: p) {
  @each $size, $lengths in $spacers {
    $length-x:   map-get($lengths, x);
    $length-y:   map-get($lengths, y);

    .#{$abbrev}a-#{$size} { #{$prop}:        $length-y $length-x !important; } // a = All sides
    .#{$abbrev}t-#{$size} { #{$prop}-top:    $length-y !important; }
    .#{$abbrev}r-#{$size} { #{$prop}-right:  $length-x !important; }
    .#{$abbrev}b-#{$size} { #{$prop}-bottom: $length-y !important; }
    .#{$abbrev}l-#{$size} { #{$prop}-left:   $length-x !important; }

  }
  @for $index from 0 through 64 {
      $size: $index * 4;
      $length: #{$size}px;

      .#{$abbrev}-#{$size} {
          #{$prop}: $length !important;
      }
  }

  @for $index from 0 through 64 {
      $size: $index * 4;
      $length: #{$size}px;

      .#{$abbrev}x-#{$size} {
          #{$prop}-right: $length !important;
          #{$prop}-left: $length !important;
      }

      .#{$abbrev}y-#{$size} {
          #{$prop}-top: $length !important;
          #{$prop}-bottom: $length !important;
      }
  }
}