@use '@angular/material' as mat;

@mixin error-styles-theme($theme) {
  $primary: map-get($theme, primary);
  .error-title {
    background: mat.get-color-from-palette($primary);
    &:after {
      border-top-color: mat.get-color-from-palette($primary);
    }
  }
}
